import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_TFT } from "@/game-tft/definition-symbol.mjs";
import fetchTFTComps from "@/game-tft/fetch-tft-comps-data.mjs";
import { CONTENTFUL_TAGS } from "@/shared/contentful-constants.mjs";
import { getEntriesByTags } from "@/shared/contentful-fetches.mjs";

export default async function fetchData(...args) {
  const gameTag = CONTENTFUL_TAGS[GAME_SHORT_NAMES[GAME_SYMBOL_TFT]];
  await Promise.all([
    fetchTFTComps(...args),
    getEntriesByTags([gameTag, CONTENTFUL_TAGS.TILES_GRID], 4),
    getEntriesByTags([gameTag, CONTENTFUL_TAGS.TILES_LIST], 4),
  ]);
}
